<template>
  <div>
    <van-form @submit="onSubmit">
      <!--账号-->
      <van-field v-model="account" name="account" label="账号" placeholder="账号" disabled>
      </van-field>

      <!--系统-->
      <van-field readonly clickable name="os" :value="osText" label="系统" placeholder="选择系统" disabled />

      <!--游戏-->
      <van-field readonly clickable name="game" :value="gameText" label="游戏" placeholder="选择游戏(请先选择系统)" disabled />

      <!--区服-->
      <van-field readonly clickable name="server" :value="serverText" label="区服" placeholder="选择区服(请先选择游戏)" disabled />

      <!--角色-->
      <van-field readonly clickable name="role" :value="roleText" label="角色名" placeholder="选择角色(请先选择区服)" disabled />

      <!--金额-->
      <van-field readonly clickable name="money" :value="moneyText" label="充值金额" placeholder="选择金额"
        @click="moneyShowPicker = true" :rules="[{ required: true }]" />
      <van-popup v-model="moneyShowPicker" position="bottom">
        <van-picker show-toolbar :columns="moneyList" @confirm="moneyConfirm" @cancel="moneyShowPicker = false" />
      </van-popup>

      <!--充值道具-->
      <van-field v-model="product_name" name="product_name" label="充值道具" placeholder="充值道具" readonly />

      <div v-if="payTypeId == 20 && (!user_email || user_email == '')">
        <van-field v-model="first_name" maxlength="10" name="first_name" label="姓" placeholder="请输入姓"
          :rules="[{ required: true, message: '请输入姓' }]" />
        <van-field v-model="last_name" maxlength="10" name="last_name" label="名字" placeholder="请输入名字"
          :rules="[{ required: true, message: '请输入名字' }]" />
        <van-field v-model="email" name="email" maxlength="30" label="邮箱" placeholder="请输入邮箱"
          :rules="[{ required: true, message: '请输入邮箱' }, { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: '邮箱格式错误！' }]" />
      </div>

      <van-notice-bar v-show="discount_show == true" wrapable :scrollable="false" :text="discount_info" />

      <!--支付方式-->
      <van-cell title="支付方式"> </van-cell>

      <van-radio-group v-model="payTypeId">
        <van-cell-group>
          <!-- 微信 -->
          <van-cell clickable @click="payTypeId = '2'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/wechat.png')" />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
          <!-- 支付宝 -->
          <van-cell clickable @click="payTypeId = '1'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/alipay.png')" />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          
          <!-- paypay-->
          <van-cell clickable @click="payTypeId = '3'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/paypal.png')" />
            <div class="pay_desc">（支持外币，账单显示金额为美元 USD）</div>
            <template #right-icon>
              <van-radio name="3" />
            </template>
          </van-cell>
          <!-- 连连支付-->
          <!-- <van-cell clickable @click="payTypeId = '13'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pcard.png')"/>
            <div class="pay_paypal_desc">（支持外币，账单显示金额为美元 USD）<br><span class="pay_paypal_tip">结算汇率按照实际汇率实时变动</span></div>
            <template #right-icon>
              <van-radio name="13" />
            </template>
          </van-cell> -->
          <!-- Airwallex支付-->
          <van-cell clickable @click="payTypeId = '20'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pcard3.png')" />
            <div class="pay_paypal_desc">（支持外币，账单显示金额为美元 USD）
              <br>
              <span class="pay_paypal_tip" style="margin-left: 0.1rem;">结算汇率按照实际汇率实时变动</span>
            </div>
            <template #right-icon>
              <van-radio name="20" />
            </template>
          </van-cell>
          <!-- stripe支付-->
          <van-cell clickable @click="payTypeId = '10'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pcard2.png')"/>
            <div class="pay_paypal_desc">（支持外币，账单显示金额为美元 USD）
              <br>
              <span class="pay_paypal_tip" style="margin-left: 0.1rem;">结算汇率按照实际汇率实时变动</span>
            </div>
            <template #right-icon>
              <van-radio name="10"/>
            </template>
          </van-cell>
          <!-- mycard -->
          <van-cell clickable @click="payTypeId = '15'" v-show="mycardShow">
            <van-image class="pay_icon" :src="require('@/assets/common/mycard.png')" />
            <div class="pay_desc">（支持外币，台币）</div>
            <template #right-icon>
              <van-radio name="15" />
            </template>
          </van-cell>
          <!-- paypay银行卡-->
          <!-- <van-cell clickable @click="payTypeId = '14'">
            <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/pcard.png')" />
            <div class="pay_paypal_desc">（支持外币，账单显示金额为美元 USD）<br><span class="pay_paypal_tip"
                style="margin-left: 0.1rem;">结算汇率按照实际汇率实时变动</span></div>
            <template #right-icon>
              <van-radio name="14" />
            </template>
          </van-cell> -->

        </van-cell-group>
      </van-radio-group>

      <div style="margin: 16px">
        <van-button style="width: 45%;float:left;margin-left: 30%;" type="primary">立即充值</van-button>
      </div>
    </van-form>
    <van-cell title="温馨提醒：充值时请确保角色在本服（非跨服地图）！" style="color: red" />
    <!-- 优惠券列表 -->
    <van-popup v-model="showCouponList" round position="bottom" style="height: 90%; padding-top: 4px">
      <van-coupon-list :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons"
        :show-exchange-bar="false" @change="onCouponChange" />
    </van-popup>
  </div>
</template>

<script>
import {
  productsList,
  commonCreateOrder,
  payMix,
  payWxRedirect,
  payLianLian,
  payMycard
} from "@/api/secret";
import {
  stripePayment,
  AirwallexPayment
} from "@/api/stripePay";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { couponList } from "@/api/user";
import { getYearMonthDay } from '@/utils/format'
import { redirectToCheckout, loadAirwallex } from 'airwallex-payment-elements';

loadAirwallex({
  env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
});
const PAY_Airwallex = 20
const PAY_TYPE_WX = 2
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_PAYPAL = 3
const PAY_PAL_CARD = 14
const PAY_LIAN_LIAN = 13
const PAY_MYCARD_CARD = 15
const PAY_STRIPE = 10

export default {
  data() {
    return {
      account: "vs407263", //账号
      accountList: [],
      mycardShow: true,//  mycard测试
      accountShowPicker: false,
      user_id: 72745, //用户id
      game_id: "493b017350e84f2f9328e7ca41dac96c", //游戏id
      is_sale: 0, //是否打折 0不打折
      discount_info: "恭喜你，本次充值额外赠送10%！", //折扣信息
      discount_show: false, //显示打折信息
      product_id: 0, //后台自增id 非product_id
      product_name: "", //物品名
      payState: false, //防止重复点击
      osId: "",
      osText: '苹果', //选中内容
      osShowPicker: false,
      gameText: "怒火大陆", //选中游戏
      serverText: "怒火65服", //选中区服
      serverList: [], //区服列表
      serverShowPicker: false,
      roleText: "屠龙辗", //选中角色
      roleList: [], //角色列表
      roleId: 0,
      roleShowPicker: false,
      moneyText: "", //选中金额
      moneyList: [], //金额列表
      moneyShowPicker: false,
      payTypeId: "2",
      chosenCoupon: -1,
      showCouponList: false,
      coupons: [],
      couponList: [],
      disabledCoupons: [],
      couponId: undefined,
    };
  },
  mounted: function () {
    const img = document.querySelector(`img[src="https://wx.playhy.com/card-mark.png"]`);
    if (img) {
      img.remove()
    }
    const p = document.querySelector(`p`);
    if (p) {
      p.remove()
    }
  },
  created() {
    //  获取档位列表
    this.getProduct();
  },
  methods: {
    onSubmit() {
      const that = this;
      if (that.payState) {
        return false;
      }
      that.payState = true;
      Toast.loading({
        message: "登录中，请稍等!",
        forbidClick: true,
      });
      console.log("payTypeId", parseInt(this.payTypeId));
      this.orderStore()
    },
    getProduct() {
      productsList({ game_id: this.game_id, os: 2 })
        .then((response) => {
          this.$toast({
            message: "获取充值金额列表成功",
            duration: 500,
          });
          this.moneyList = response;
        })
        .catch(() => { });
    },
    moneyConfirm(value) {
      this.moneyText = value.text;
      this.product_id = value.id;
      this.product_name = value.product_name;
      this.moneyShowPicker = false;
      this.discount_show = false;
      this.coupons = [];
      this.disabledCoupons = [];
      this.couponList.forEach((el) => {
        let coupon = {
          coupon_id: el.id,
          available: 1,
          reason: el.desc,
          value: el.discount * 100,
          name: el.name,
          valueDesc: parseInt(el.discount),
          unitDesc: "元",
          description: el.desc,
          customValidPeriod: '有效期至 ' + getYearMonthDay(new Date(el.expiry_at).getTime())
        };
        if (parseInt(el.over) === 0) {
          coupon.condition = '直减'
        } else {
          coupon.condition = '满 ' + parseInt(el.over) + ' 元可用'
        }
        // 如果充值金额大等于代金券满金额
        if (
          parseFloat(el.over) === 0 ||
          parseFloat(this.moneyText) >= parseFloat(el.over)
        ) {
          this.coupons.push(coupon);
        } else {
          this.disabledCoupons.push(coupon);
        }
      });
    },
    changeReset(changeSetup) {
      switch (changeSetup) {
        case "all":
          this.user_id = 0;
          this.account = "";
        /* falls through */
        case "account":
          this.osId = "";
          this.osText = "";
          console.log("changeSetup account");
        /* falls through */
        case "os":
          this.game_id = 0;
          this.gameText = "";
        /* falls through */
        case "game":
          this.serverText = "";
          this.serverList = [];
        /* falls through */
        case "server":
          this.roleText = "";
          this.roleId = 0;
          this.roleList = [];
        /* falls through */
        case "role":
          this.moneyText = this.product_name = "";
          this.moneyList = [];
          this.discount_show = false;
          this.product_id = 0;
          this.payTypeId = "2";
          break;
      }
    },
    onCouponChange(index) {
      this.showCouponList = false
      this.chosenCoupon = index
      if (index >= 0) {
        this.couponId = this.coupons[index]["coupon_id"]
      } else {
        this.couponId = undefined
      }
    },
    getCouponList() {
      couponList({ account: this.account, user_id: this.user_id }).then(
        (response) => {
          this.couponList = response;
          this.coupons = [];
          this.disabledCoupons = [];
          this.chosenCoupon = -1;
        }
      );
    },
    resetMoney() {
      this.moneyText = this.product_name = this.product_id = undefined
      // this.getCouponList()
    },
    // 创建订单
    orderStore() {
      const payTypeId = parseInt(this.payTypeId)
      commonCreateOrder({
        //  正式账号
        user_id: 72745,
        game_id: "493b017350e84f2f9328e7ca41dac96c",
        server: "怒火65服",
        role_name: "屠龙辗",

        // //  测试账号
        // user_id: 19571,
        // game_id: this.game_id,
        // server: "怒火6服",
        // role_name: "舒圣杰",

        product_id: this.product_id,
        os: "苹果",
        pay_type_id: payTypeId,
        coupon_id: this.couponId,
      }).then((response) => {
        this.resetMoney()
        this.payState = false
        // 如果代金券直接减完支付完成
        if (response.pay_success && response.pay_success === true) {
          this.$toast.success('支付成功')
        } else {
          this.toPay(response.number)
        }
      }).catch(() => {
        this.payState = false
      })
    },
    // 发起支付
    toPay(orderNum) {
      Toast.loading({
        message: "发起支付中，请稍等!",
        forbidClick: true,
        duration: 0,
      });
      const payTypeId = parseInt(this.payTypeId)
      if (payTypeId === PAY_TYPE_WX) {
        this.payWx(orderNum)
      } else if (payTypeId === PAY_TYPE_ALIPAY) {
        this.payAlipay(orderNum)
      } else if (payTypeId === PAY_MYCARD_CARD) {
        this.payByMyCard(orderNum)
      } else if (payTypeId === PAY_TYPE_PAYPAL) {
        this.payPaypal(orderNum)
      } else if (payTypeId === PAY_LIAN_LIAN) {
        this.payByLianLian(orderNum)
      } else if (payTypeId === PAY_PAL_CARD) {
        this.payPaypalCard(orderNum)
      } else if (payTypeId === PAY_STRIPE) {
        this.payByStripe(orderNum)
      } else if (payTypeId === PAY_Airwallex) {
        this.payByAirwallex(orderNum)
      }
    },
    //空中云汇支付
    payByAirwallex(orderNum) {
      AirwallexPayment({
        order_num: orderNum,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
      }).then((response) => {
        console.log(response)

        const intent_id = response.intent_id;
        const client_secret = response.client_secret;
        const currency = response.currency;
        redirectToCheckout({
          env: 'prod',
          mode: 'payment',
          currency,
          intent_id, // Required, must provide intent details
          client_secret, // Required
          theme: {
            // Must provide theme to display the checkout page properly
            fonts: [
              // Customizes the font for the payment elements
              {
                src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
                family: 'AxLLCircular',
                weight: 400,
              },
            ],
          },
          successUrl: 'https://wx.playhy.com/#/paySuccess?url_type=secret', // Must be HTTPS sites
          failUrl: 'https://wx.playhy.com/#/role', // Must be HTTPS sites
        });
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    //stripe支付
    payByStripe(orderNum) {
      stripePayment({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/role',
        redirect_url: 'https://wx.playhy.com/#/paySuccess?url_type=secret',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // 微信
    payWx(orderNum) {
      payWxRedirect(orderNum).then((response) => {
        Toast.clear(true);
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // PayPal
    payPaypal(orderNum) {
      payMix({
        number: orderNum,
        user_id: this.user_id,
        pay_type: parseInt(this.payTypeId),
        return_url: "https://api.hzarcticwolf.com/third/v1/offaccount/execpaypal?url_type=role"
      }).then((res) => {
        console.log(res)
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "跳转支付页面...",
          forbidClick: true,
          duration: 3000,
        });
      }).catch(() => {
        this.$toast.fail('发起支付失败')
      });
    },
    // PayPal银行卡支付页面请求
    payPaypalCard(orderNum) {
      //  Vue测试
      this.$router.push({
        path: "/paypalcard",
        query: {
          number: orderNum,
          user_id: this.user_id,
          pay_type: parseInt(this.payTypeId),
          url: 'role'
        }
      });
      Toast.clear(true)
      Toast.loading({
        message: "跳转支付页面...",
        forbidClick: true
      });
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/credit/mid",
        query: {
          key: key,
        },
      })
    },
    //连连支付
    payByLianLian(orderNum) {
      payLianLian({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/role',
        redirect_url: 'https://wx.playhy.com/#/paySuccess',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // mycard
    payByMyCard(orderNum) {
      payMycard({
        order_num: orderNum,
        user_id: this.user_id,
        type: 'official',
        return_url: "role",
      }).then((res) => {
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "跳转支付页面...",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
  },
};
</script>

<style>
.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}

.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}

.title_coupon {
  margin-right: 5px;
}

/* .van-coupon-list__list {
  height: 100% !important;
} */
</style>
